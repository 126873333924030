import React from "react";
import { Row, Col, Container, Button} from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import Divider from '../sections/akkadian/Divider';
import imgBanner from "../assets/image/akkadian/teamworking.jpg";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
import { states } from "../utils/team.js";
const Locations = () => {

    const main_string = "Akkadian Health was founded in Adelaide, South Australia. We now offer services all across Australia.";
    const secondary_string = "Our telehealth services operate in the following locations:";

    return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
          <title>Akkadian Health - Locations | Psychiatrists | Australia </title>
		  <meta
                name="description"
                content="Akkadian Health locations across Australia"
            />
        </Helmet>
				  <style>{`
					table{
					 width:90%;
					}
					table, th, td {
					  border: 1px solid white;
					  border-collapse: collapse;
					  padding: 8px;
					  color: white;
					  margin-left: 5%
					}
					th, td {
					  background-color: #2fbfcf;
					}
				  `}</style>

        <div className="inner-banner">
            <Container>

            <Row id="about-us" className="justify-content-center mt-md-3 pt-24 pt-lg-29">
					<Col lg="9" xl="8">
						<div className="px-md-6 text-center mb-11 mb-lg-14">
							<h1 className="title gr-text-3 mb-9 mb-lg-12">Our Locations</h1>
                        </div>
                    </Col>
            </Row>

			<Row className="justify-content-center align-items-center">
				<Col md="12">
					<div className="section-title text-center mb-12 mb-lg-16">
						<p className="gr-text-10 px-lg-8 mb-0">
							{main_string}
						</p>                        
					</div>
				</Col>
			</Row>					
				
            <Row className="justify-content-center mb-8">
                <Col lg="8" className="text-center">
                    <img src={imgBanner} className="img-fluid" alt="banner" />
                </Col>
            </Row>

			<Row className="justify-content-center align-items-center pt-12">
				<Col md="12">
					<div className="section-title text-center mb-12 mb-lg-16">
						<h2 className="gr-text-10 px-lg-8">
                            {secondary_string}
						</h2>                            
					</div>
				</Col>
			</Row>		

            <Row className="justify-content-center pt-10 gr-text-8 mb-8 px-4 mx-auto">

				{states.map(state => {
					if(state.filename) {
						return (
							
                                <Col key={state.name} lg="4" md="4" s="6" xs="12" className="mb-3 text-center">
								<Button
									variant="outline-secondary"
									style={{backgroundColor: state.color, borderColor: state.color, color: state.textColor, fontWeight: "bold"}}
									className="mr-2 mb-2"
									onClick={() => window.location.href = `/locations/${state.filename.toLowerCase()}`}
								>
									{state.name}
								</Button>
                                </Col>				
						)
					} else {
						return null;
					}
				})}

            </Row>
				
				<hr />


                <Row className="justify-content-center pt-10 pt-lg-17">
                        <Link to="/for-specialists" className={`btn btn-primary gr-hover-y`}>
                            Join us
                        </Link>
                </Row>
                <br />
            </Container>
        </div>
        <Divider />

				
				
			
      </PageWrapper>
    </>
		
		
    );
};

export default Locations;